
@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

.material-icons-outlined,
.material-icons-round {
  font-size: 1.5rem;
}


h1 {
  font-size: 2.5rem;
  line-height: 1.2;
}
h2 {
  font-size: 2rem;
  line-height: 1.2;
}
h3 {
  font-size: 1.75rem;
  line-height: 1.2;
}
h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}
h5 {
  font-size: 1.25rem;
  line-height: 1.2;
}
h6 {
  font-size: 1rem;
  line-height: 1.2;
}
p {
  line-height: normal;
}