$modalBodyPaddingLeft: 1.5rem !default;
$modalBodyPaddingRight: 1.5rem !default;
$modalBodyPaddingTop: 0.5rem !default;
$modalBodyPaddingBottom: 1.5rem !default;

$modalHeaderPaddingLeft: 1.5rem !default;
$modalHeaderPaddingRight: 1.5rem !default;
$modalHeaderPaddingTop: 1.5rem !default;
$modalHeaderPaddingBottom: 0.5rem !default;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  padding: 0.5rem 0;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
      transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-bottom {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 1rem);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--surface-overlay);
  background-clip: padding-box;
  border-radius: var(--border-radius);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.4;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: $modalHeaderPaddingTop $modalHeaderPaddingRight $modalHeaderPaddingBottom $modalHeaderPaddingLeft;
  border-bottom: none;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
.compensate-modal-header-x-padding {
  margin-left: calc(-1 * $modalHeaderPaddingLeft);
  margin-right: calc(-1 * $modalHeaderPaddingRight);
}
.compensate-modal-header-y-padding {
  margin-bottom: -0.5rem;
}
.modal-header .btn-close {
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.modal-title {
  margin: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: $modalBodyPaddingTop $modalBodyPaddingRight $modalBodyPaddingBottom $modalBodyPaddingLeft;
}
.compensate-modal-body-top-padding {
  margin-top: calc(-1 * $modalBodyPaddingTop);
}
.compensate-modal-body-x-padding {
  margin-left: calc(-1 * $modalBodyPaddingLeft);
  margin-right: calc(-1 * $modalBodyPaddingRight);
}
.compensate-modal-body-x-padding-with-gutter {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0;
}
.modal-footer {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem 1rem 1.5rem;
  border-top: none;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}


@media (min-width: 576px) {
  .modal-dialog {
      max-width: 500px;
      margin: 0rem auto;
  }
  .modal-dialog-scrollable {
      height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
      min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
      max-width: 300px;
  }
  .modal-xl {
    max-width: 98%;
  }
}
@media (min-width: 992px) {
  .modal-lg {
      max-width: 800px;
  }
  .modal-xl {
      max-width: 950px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
      max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  padding: 0;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
      border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
      border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
      overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
      border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
      border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
      border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0;
  }
}




// moje
.modal.blur {
  backdrop-filter: blur(9px);
}
.modal-md-fixed {
  width: auto!important;
  .modal-content{
    width: 500px;
    max-width: 100%;
  }
}
.modal-down {
  display: flex!important;
  flex-direction: column;
  justify-content: flex-end;

  .modal-dialog {
    flex: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;

    .modal-content {
      max-height: 100%;

      .modal-body {
        overflow-y: auto;
      }
    }
  }
}

.centered-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

.modal-max-height {
  .modal-dialog {
    height: 100%;
  }
  .modal-content {
    height: 100%;
  }
  .modal-body {
    overflow-y: auto;
  }
}

.modal-full-right {

  // disable scale animation for modal-static
  &.modal-static {
    .modal-dialog {
      transform: none;
    }
  }

  .modal-dialog {
    height: 100%;
    // width: fit-content;
    padding: 0;
    margin: 0;
    margin-left: auto;

    width: 34rem;
    max-width: 100%;
  }

  .modal-content {
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    
  }

  .modal-body {
    overflow-y: auto;
  }

  &.modal-full-right--lg {
    .modal-dialog {
      width: 40rem;
    }
  }
}

.modal-2x-full-right {

  .modal-dialog {
    height: 100%;
    // width: fit-content;
    padding: 0;
    margin: 0;
    margin-left: auto;

    width: 62rem;
    max-width: 100%;
  }

  .modal-content {
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .modal-body {
    overflow-y: auto;
  }
}

//
.modal-header--highlighted {
  background: #EDF1F7;
}
.modal-body--highlighted {
  background: #EDF1F7;
}
.modal-footer--highlighted {
  background: #EDF1F7;
}
// /

.modal-footer {
  padding-top: 0.5rem;
}

.modal-body-divider {
  border-bottom: 1px solid var(--surface-d);
  margin: 1rem calc(-1 * $modalBodyPaddingLeft);
}
// shadow in modal
.modal-header--shadow {
  box-shadow: 20px 0px 30px 10px rgba(167, 184, 208, 0.25);
}
.modal-footer--shadow {
  box-shadow: 20px 0px 30px 10px rgba(167, 184, 208, 0.25);
}
//

//
.modal-footer:empty {
  padding: 0;
}
.modal-content:has(.modal-footer:empty) {
  .modal-body {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}
//