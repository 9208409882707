@import 'ng-bootstrap/modal';
@import 'ng-bootstrap/container';
@import 'ng-bootstrap/tooltip';

.d-block {
  display: block;
}


.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
      transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
