// @import 'primeflex/primeflex.scss';

// button xsm
.p-button-xsm {
  font-size: 0.85rem;
  padding: 0.35625rem 0.49375rem;
}
// button grow
.p-button-grow {
  flex: 1;
}
// button white bg
.p-button-bg-white {
  background: #fff!important;
}

// avatar group
.p-avatar-group > *:not(:first-child) {
  margin-left: -0.8rem;
}

// loading box
.loading-box,
.loading-box--secondary {
  position: relative;
  pointer-events: none;
  cursor: wait;

  & * {
    cursor: wait;
    opacity: 0.3;
  }
}
.loading-box-opacity--slight {
  & * {
    opacity: 0.9;
  }
}
.loading-box,
.loading-box--secondary {
  &::after {
    cursor: wait;
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: loading-box-spinner 1s ease infinite;
  }
}
.loading-box--secondary {
  &::after {
    border-top-color: var(--surface-0);
  }
}
@keyframes loading-box-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
//////////////////////////

// apply to table's <td> => column has min width
.table-col-min-width {
  width: 1%;
  white-space: nowrap;
}
//////////////////////////



// P-TAG
.p-tag-secondary {
  background: var(--l-secondary);
}
//////////////////////////


// p-DROPDOWN
.p-dropdown-item {
  white-space: normal!important;
}
//


// dark blue text
.text-dark-blue {
  color: var(--l-dark-blue)!important;
}
//


// p-table
.p-datatable {


  // oddeleni captionu a styl
  .p-datatable-header {
    margin-bottom: 1rem;
  }
  
  .p-datatable-header,
  .p-datatable-wrapper {
    border: 1px solid #DEE2E6;
    border-radius: var(--border-radius);
  }

  .p-datatable-table {

    // zaobleni
    .p-datatable-thead {
      tr th:first-child {
        border-top-left-radius: var(--border-radius);
      }
      tr th:last-child {
        border-top-right-radius: var(--border-radius);
      }
    }
    .p-datatable-tbody tr:last-child{
      & td:first-child {
        border-bottom-left-radius: var(--border-radius);
      }
      & td:last-child {
        border-bottom-right-radius: var(--border-radius);
      }
    }
    // konec zaobleni


  }

}

.table-second-row {
  font-size: .85rem;
  color: var(--bluegray-400);
}
//


// text editors add p for each line
.inner-html {
  p {
    margin: 0;
  }
}
//

// increase z-index of loading overlay
.p-datatable-loading-overlay {
  z-index: 3!important;
}
//

// info block - text • text • text
.details-block-wrap {
  font-size: 0.85rem;
  color: var(--bluegray-400);

  .detail-block {
    display: inline-flex;
    align-items: baseline;
  }
  .detail-block::after {
    color: var(--bluegray-100);
    font-size: 1rem;
    content: "•";
    padding: 0 5px;
  }
  .detail-block:last-child::after {
    content: "";
    padding: 0;
  }
}
// /


// defaultne nejsou bottom rohy zaobleny
.p-panel:not(.p-panel-expanded) .p-panel-header {
  border-radius: var(--border-radius);
}
//


// dark blue button TODO predelat - pridat ho do theme - generovat to
.p-button.p-button-dark-blue, .p-buttonset.p-button-dark-blue > .p-button, .p-splitbutton.p-button-dark-blue > .p-button {
  color: #ffffff;
  background: var(--l-dark-blue);
  border: 1px solid var(--l-dark-blue);
}
.p-button.p-button-dark-blue:enabled:hover, .p-buttonset.p-button-dark-blue > .p-button:enabled:hover, .p-splitbutton.p-button-dark-blue > .p-button:enabled:hover {
  background: var(--l-dark-blue);
  color: #ffffff;
  border-color: var(--l-dark-blue);
}
.p-button.p-button-dark-blue:enabled:active, .p-buttonset.p-button-dark-blue > .p-button:enabled:active, .p-splitbutton.p-button-dark-blue > .p-button:enabled:active {
  background: var(--l-dark-blue);
  color: #ffffff;
  border-color: var(--l-dark-blue);
}
.p-button.p-button-dark-blue.p-button-outlined, .p-buttonset.p-button-dark-blue > .p-button.p-button-outlined, .p-splitbutton.p-button-dark-blue > .p-button.p-button-outlined {
  background-color: transparent;
  color: var(--l-dark-blue);
  border: 1px solid;
}
.p-button.p-button-dark-blue.p-button-outlined:enabled:hover, .p-buttonset.p-button-dark-blue > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-dark-blue > .p-button.p-button-outlined:enabled:hover {
  background: rgba(2, 35, 84, 0.05);
  color: var(--l-dark-blue);
  border: 1px solid;
}
.p-button.p-button-dark-blue.p-button-outlined:enabled:active, .p-buttonset.p-button-dark-blue > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-dark-blue > .p-button.p-button-outlined:enabled:active {
  color: var(--l-dark-blue);
  border: 1px solid;
}

// /


// tag - jednoduchy, sedy tag
.tag {
  padding: 0.25rem 0.9rem;
  border-radius: var(--border-radius);
  background-color: var(--bluegray-200);
  color: #fff;
  font-size: 0.75rem;
}
// 
// /

// tabview
.p-tabview-nav-link {
  box-shadow: none!important;
  white-space: nowrap;
}
// /


// pulse
.pulse--green {
  box-shadow: 0 0 0 rgba(30, 169, 124, 0);
  border-radius: 50%;
  animation: pulse-green 2s infinite;
}
@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0px rgba(30, 169, 124, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(30, 169, 124, 0);
  }
}
.pulse--cyan {
  box-shadow: 0 0 0 rgba(53, 196, 220, 0);
  border-radius: 50%;
  animation: pulse-cyan 2s infinite;
}
@keyframes pulse-cyan {
  0% {
    box-shadow: 0 0 0 0px rgba(53, 196, 220, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(53, 196, 220, 0);
  }
}
.pulse--teal {
  box-shadow: 0 0 0 rgba(65, 197, 183, 0);
  border-radius: 50%;
  animation: pulse-teal 2s infinite;
}
@keyframes pulse-teal {
  0% {
    box-shadow: 0 0 0 0px rgba(65, 197, 183, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(65, 197, 183, 0);
  }
}
// /
//


// input label
.input-label {
  font-size: 0.75rem;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--bluegray-400);
}
//


// session attendance statuses
.session-attendance-status {

  $present: var(--green-500);
  $replaced: var(--primary-color);
  $absent-excused: var(--orange-300);
  $absent-not-excused: var(--red-500);
  $no-attendance-yet: var(--bluegray-400);
  
  color: #ffffff;
  &-bg {

    &--present {
      background: $present;
      border-color: $present;
    }
  
    &--replaced {
      background: $replaced;
      border-color: $replaced;
    }
  
    &--absent-excused {
      background: $absent-excused;
      border-color: $absent-excused;
    }
  
    &--absent-not-excused {
      background: $absent-not-excused;
      border-color: $absent-not-excused;
    }
  
    &--no-attendance-yet {
      background: $no-attendance-yet;
      border-color: $no-attendance-yet;
    }
  }
  &-color {

    &--present {
      color: $present;
    }
  
    &--replaced {
      color: $replaced;
    }
  
    &--absent-excused {
      color: $absent-excused;
    }
  
    &--absent-not-excused {
      color: $absent-not-excused;
    }
  
    &--no-attendance-yet {
      color: $no-attendance-yet;
    }
  }
}
// /


// blank input
.blank-input {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: inherit;
  box-shadow: none;

  &::placeholder {
    color: inherit;
  }

  &.ng-invalid.ng-dirty::placeholder {
    color:var(--l-danger);
  }

  // hide number input arrows
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  &[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
}
// /
// si module blank input wrap
.si-module-blank-input-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.4rem;
  border-color: transparent;

  &:hover {
    background: var(--surface-ground);
  }

  &.invalid {
    border-color: #e24c4c;
  }
}
// /
// si module header blank input style
.si-module-header-blank-input {
  min-width: 2ch!important;
  text-align: right;
  color: var(--l-dark-blue);
  font-size: 1.5rem;
  &::placeholder {
    font-size: 1.65;
  }
}
// /

// primeng gridline table 1px border helper (p-datatable-gridlines adds border to every cell, which results in 2px borders)
.table-gridline {
  td, th {
    border-top: none;
  }
  
  td:not(:last-child),
  th:not(:last-child) {
    border-left: none;
  }
  
  td:last-child,
  th:last-child {
    border-right: none;
  }
  
  tbody tr:last-child {
    td,
    th {
      border-bottom: none;
    }
  }
  
  td:nth-last-child(2),
  th:nth-last-child(2) {
    border-right: none;
  }  
}
//

// transparent ngbtooltip
.transparent-tooltip {
  opacity: 1 !important;
}

.transparent-tooltip .tooltip-inner {
  padding: 0px 0px 0px 0px;
  color: #ffffff00;
  background: #ffffff00;
  max-width: 400px;
  width: min-content;
}

.transparent-tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #ffffff00;
}
.transparent-tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #ffffff00;
}
.transparent-tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #ffffff00;
}
.transparent-tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #ffffff00;
}
//



// subtle input
@mixin inputSubtleWrappingStyles($variant: grey) {
  border-radius: 4px;
  padding: 1px 2px;

  @if $variant == grey {
    color: var(--bluegray-400);
    background: #EDF1F7;
    border: 1px solid #EDF1F7;
  } @else if $variant == light {
    color: var(--text-color-secondary);
    background: transparent;
    border: 1px solid transparent;
  }
}
@mixin inputSubtleWrappingActiveStyles($variant: grey) {
  @if $variant == grey {
    background: var(--surface-d);
    border-color: var(--surface-d);
  } @else if $variant == light {
    background: var(--surface-card);
    border-color: var(--surface-card);
  }
}
@mixin inputSubtleStyles {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.5;
}
@mixin inputSubtleErrorStyles($variant: grey) {
  border-color: var(--l-danger);
  @if $variant == light {
    background: var(--red-50);
  }
}

.input-subtle--fake {
  @include inputSubtleStyles;
  @include inputSubtleWrappingStyles;
  cursor: pointer;
  display: inline-flex;

  &:hover,
  &:focus {
    @include inputSubtleWrappingActiveStyles;
  }
}

input.input-subtle,
textarea.input-subtle,
.input-subtle input {
  @include inputSubtleStyles;

  border: none;
  box-shadow: none;
}


.p-inputtext.input-subtle {
  @include inputSubtleWrappingStyles;

  &:hover,
  &:focus {
    @include inputSubtleWrappingActiveStyles;
  }
}
.p-inputtext.input-subtle--light {
  @include inputSubtleWrappingStyles(light);

  &:hover,
  &:focus,
  &:placeholder-shown {
    @include inputSubtleWrappingActiveStyles(light);
  }
}
p-dropdown.input-subtle {
  
  .p-dropdown-label {
    @include inputSubtleStyles;
  }
  .p-inputtext {
    border: none;
    padding: 0;
  }
  .p-dropdown {
    @include inputSubtleWrappingStyles;
    &:hover,
    &:focus {
      @include inputSubtleWrappingActiveStyles;
    }

    border: none;
    box-shadow: none;
  }
  .p-dropdown-trigger {
    @include inputSubtleStyles;
    width: 1.8rem;
  }
}
p-calendar.input-subtle {
  input {
    @include inputSubtleWrappingStyles;
    &:hover,
    &:focus {
      @include inputSubtleWrappingActiveStyles;
    }
  }
}

.input-subtle p-inputMask input {
  @include inputSubtleStyles;
  @include inputSubtleWrappingStyles;
  &:hover,
  &:focus {
    @include inputSubtleWrappingActiveStyles;
  }
}

// errors should be last
.input-subtle:has(.ng-dirty.ng-invalid) input,
.input-subtle:has(.ng-dirty.ng-invalid) textarea,
.input-subtle.ng-dirty.ng-invalid {
  @include inputSubtleErrorStyles;
}
.input-subtle--light:has(.ng-dirty.ng-invalid) input,
.input-subtle--light:has(.ng-dirty.ng-invalid) textarea,
.input-subtle--light.ng-dirty.ng-invalid {
  @include inputSubtleErrorStyles(light);
}
// /


// auto resized
textarea.auto-resized {
  resize: none;
  overflow: hidden;
}
input.auto-resized,
textarea.auto-resized {
  min-width: 2ch;
}
// /


