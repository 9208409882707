

.modal-footer {
  flex-direction: row;
  justify-content: flex-end;
}

.field {
  margin: 0.5rem 0;
}



// fix pro multiselect kde pri hodne vybranych itemech ten text zvlastne overflowuje a ovlivnuje parenty
.p-multiselect-label-container {
  display: grid;
  justify-content: start;
}


.p-button.p-button-xsm.p-button-icon-only {
  width: 2rem;
}
.p-button.p-button-xsm.p-button-icon-only.p-button-rounded {
  height: 2rem;
}


.p-button.p-button-sm.p-button-icon-only {
  width: 2.65rem;
}
.p-button.p-button-sm.p-button-icon-only.p-button-rounded {
  height: 2.65rem; // stejna vyska jak .p-button-sm
}


// primeflex override
@supports (height: 100dvh) {
  .h-100dvh {
    height: 100dvh;
  }
}
@supports (min-height: 100dvh) {
  .min-h-100dvh {
    min-height: 100dvh;
  }
}
//